/* Box Sizing */
/* --------------------------------------------- */

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* HTML */
/* --------------------------------------------- */

html,
body {
  height: 100%;
}

/* Body */
/* --------------------------------------------- */

body {
  margin: 0;
  padding: 0;
  overflow: auto;
  background-color: #f9f9f9 !important;
  font-family: 'proxima nova', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Root
--------------------------------------------- */

#root,
#root > .App {
  height: 100%;
}

/* TinyMCE
--------------------------------------------- */

.tox-tinymce {
  border-radius: 0.25rem !important;
  border: 1px solid #d1dbe3 !important;
}

.tox .tox-toolbar-overlord {
  background-color: #f7f7f7 !important;
}

.tox .tox-editor-header {
  padding: 0 !important;
  box-shadow: none;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: none !important;
  background-color: #f7f7f7 !important;
  border-bottom: 1px solid #d1dbe3;
}

.tox .tox-tbtn {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.tox .tox-tbtn .tox-icon {
  width: 1rem !important;
  height: 1rem !important;
}

.tox .tox-tbtn .tox-icon svg {
  display: none;
}

.tox .tox-tbtn[aria-label='Numbered list'] .tox-icon {
  background: url('./assets/images/tinymce/editor_ol.svg') center center / contain no-repeat !important;
}

.tox .tox-tbtn[aria-label='Bullet list'] .tox-icon {
  background: url('./assets/images/tinymce/editor_ul.svg') center center / contain no-repeat !important;
}

.tox .tox-tbtn[aria-label='Align left'] .tox-icon {
  background: url('./assets/images/tinymce/editor_align_left.svg') center center / contain no-repeat !important;
}

.tox .tox-tbtn[aria-label='Align center'] .tox-icon {
  background: url('./assets/images/tinymce/editor_align_center.svg') center center / contain no-repeat !important;
}

.tox .tox-tbtn[aria-label='Insert/edit link'] .tox-icon {
  background: url('./assets/images/tinymce/editor_link.svg') center center / contain no-repeat !important;
}

.tox .tox-tbtn[aria-label='Bold'] .tox-icon {
  background: url('./assets/images/tinymce/editor_bold.svg') center center / contain no-repeat !important;
}

.tox .tox-tbtn[aria-label='Italic'] .tox-icon {
  background: url('./assets/images/tinymce/editor_italic.svg') center center / contain no-repeat !important;
}

.tox .tox-tbtn[aria-label='Underline'] .tox-icon {
  background: url('./assets/images/tinymce/editor_underline.svg') center center / contain no-repeat !important;
}
